import {
  NavigationDrawerList,
  SidebarFooter,
} from "@/app/(partner)/_components/sidebar";
import { type NavigationDrawerListProps } from "@/app/(partner)/_components/sidebar/NavigationDrawerList";

// items with pvOnly: true will be hidden if the partner only offers heatpumps
export const sideBarMenuItems: NavigationDrawerListProps["menuItems"] = [
  {
    title: "Übersicht",
    sectionId: "uebersicht",
    items: [
      {
        id: "leads",
        label: "Leads",
        href: "/partner/ihre-angebotsanfragen/",
        iconName: "requests",
        itemType: "link",
      },
      {
        id: "statistik",
        label: "Statistik",
        href: `/partner/statistik/`,
        iconName: "stats",
        itemType: "link",
      },
      {
        id: "preisatlas",
        label: "Preisatlas",
        href: "/partner/preisatlas/",
        iconName: "map",
        itemType: "link",
        pvOnly: true,
      },
      {
        id: "konkurrenz",
        label: "Konkurrenz",
        href: "/partner/konkurrenzabwanderung/",
        iconName: "web",
        itemType: "link",
        pvOnly: true,
      },
      {
        id: "regionale-performance",
        href: "/partner/performance-analyse/",
        label: "Regionale Performance",
        iconName: "location",
        itemType: "link",
        pvOnly: true,
      },
    ],
  },
];

export default function PartnerSidebarContent() {
  return (
    <div className="flex h-full flex-col justify-between">
      <NavigationDrawerList menuItems={sideBarMenuItems} />
      <SidebarFooter />
    </div>
  );
}
