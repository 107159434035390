"use client";

import { Sidebar } from "@/app/(partner)/_components/sidebar";
import PartnerSidebarContent from "./PartnerSidebarContent";

export default function PartnerSidebar() {
  return (
    <Sidebar className="fixed z-50 hidden h-screen-minus-64 laptop:flex">
      <PartnerSidebarContent />
    </Sidebar>
  );
}
