import Button from "@/app/_components/button";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { trpc } from "@/trpc/client";
import NavigationDrawerItem from "../sidebar/NavigationDrawerItem";

type LogoutButtonProps = {
  type: "Button" | "NavItem";
};
export const LogoutButton = ({ type }: LogoutButtonProps) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  const logoutMutation = trpc.auth.logout.useMutation({
    onMutate: () => setIsLoading(true),
    onError: () => {},
    onSettled: () => {
      setIsLoading(false);
    },
    onSuccess: data => {
      router.replace("/anmelden");
    },
  });
  const logout = () => {
    logoutMutation.mutate();
  };
  if (type === "NavItem") {
    return (
      <NavigationDrawerItem
        onClick={logout}
        as="button"
        label="Ausloggen"
        iconName="logout"
      />
    );
  }
  return (
    <Button
      aria-label="Ausloggen"
      className="hidden font-semibold hover:text-neutral-700 laptop:flex desktop:text-base"
      size="medium"
      leadingIcon="logout"
      isDisabled={isLoading}
      onPress={logout}
    >
      <span>Ausloggen</span>
    </Button>
  );
};
