"use client";

import { useSidebarContext } from "@/app/(partner)/_components/sidebar";

interface PartnerPageWrapperProps {
  children: React.ReactNode;
}

const PartnerPageWrapper: React.FC<PartnerPageWrapperProps> = ({
  children,
}) => {
  const { isFixedOpen } = useSidebarContext();

  return (
    <div
      className={`${
        isFixedOpen
          ? "laptop:ml-[16.5rem] desktop:ml-[20rem]"
          : "laptop:ml-[5.5rem]"
      }  flex w-full flex-col gap-y-5 overflow-hidden p-4
      tablet:p-5 laptop:gap-y-6 laptop:p-8 laptop:pl-12 max:gap-y-8`}
    >
      {children}
    </div>
  );
};

export default PartnerPageWrapper;
