"use client";

import { createContext, useContext, useState, useMemo } from "react";

interface ISidebarContext {
  isExpanded?: boolean;
  setIsExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  isFixedOpen?: boolean;
  setIsFixedOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  partnerInfo?: PartnerInfo;
}

const SidebarContext = createContext<ISidebarContext>({
  isExpanded: false,
  setIsExpanded: () => null,
  isFixedOpen: false,
  setIsFixedOpen: () => null,
  partnerInfo: undefined,
});

export const SidebarProvider = ({
  children,
  context = undefined,
}: {
  children: React.ReactElement;
  context?: ISidebarContext;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    context?.isExpanded || false,
  );
  const [isFixedOpen, setIsFixedOpen] = useState<boolean>(
    context?.isFixedOpen || false,
  );

  const contextValue = useMemo(
    () => ({
      isExpanded,
      setIsExpanded,
      isFixedOpen,
      setIsFixedOpen,
      partnerInfo: context?.partnerInfo,
    }),
    [
      isExpanded,
      setIsExpanded,
      isFixedOpen,
      setIsFixedOpen,
      context?.partnerInfo,
    ],
  );

  return (
    <SidebarContext.Provider value={contextValue}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => {
  const sidebarContext = useContext(SidebarContext);
  if (!sidebarContext) {
    throw new Error("useSidebarContext must be used within SidebarProvider");
  }
  return sidebarContext;
};
