"use client";

import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import {
  Button,
  Dialog,
  DialogTrigger,
  Modal,
  ModalOverlay,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";

import Icon from "@/app/_components/icon";
import { SidebarContentWrapper } from "@/app/(partner)/_components/sidebar";
import { useSidebarContext } from "@/app/(partner)/_components/sidebar/useSidebar";
import PartnerSidebarContent from "./PartnerSidebarContent";

export default function MobileMenu() {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const { setIsFixedOpen } = useSidebarContext();

  /* Close the menu when the route changes, i.e. the user clicked a menu item */
  const pathname = usePathname();
  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);

  return (
    <DialogTrigger>
      <Button
        onPress={() => {
          setMenuOpen(!isMenuOpen);
          setIsFixedOpen?.(!isMenuOpen);
        }}
        aria-label={isMenuOpen ? "Menü schließen" : "Menü öffnen"}
      >
        <Icon
          name="menu"
          className={twMerge("h-6 w-6", isMenuOpen && "hidden")}
        />
        <Icon
          name="close"
          className={twMerge("h-6 w-6", !isMenuOpen && "hidden")}
        />
        <span aria-label="Menü offnen" className="sr-only">
          Menü
        </span>
      </Button>
      <ModalOverlay
        isOpen={isMenuOpen}
        onOpenChange={setMenuOpen}
        className="overflow-none fixed inset-0 z-50 mt-14 flex justify-end border-t focus:outline-none tablet:mt-16 tablet:border-0 laptop:hidden"
      >
        <Modal className="relative h-full overflow-auto bg-background entering:animate-in entering:slide-in-from-right exiting:animate-out exiting:slide-out-to-right">
          <Dialog className="h-full outline-none">
            <SidebarContentWrapper>
              <PartnerSidebarContent />
            </SidebarContentWrapper>
          </Dialog>
        </Modal>
      </ModalOverlay>
    </DialogTrigger>
  );
}
