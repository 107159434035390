import { forwardRef } from "react";
import classNames from "classnames";

import { useSidebarContext } from "./useSidebar";

interface SidebarContentProps {
  children: React.ReactNode;
}

const SidebarContentWrapper = forwardRef<HTMLDivElement, SidebarContentProps>(
  ({ children }, ref) => {
    const { isExpanded, isFixedOpen } = useSidebarContext();
    return (
      <div
        ref={ref}
        className={classNames(
          "flex h-full w-20 min-w-0 flex-col items-start gap-y-5 overflow-hidden bg-background p-4 pb-2 transition-all duration-300 ease-out tablet:w-24 tablet:px-5 tablet:pt-5 laptop:w-28 laptop:px-8 laptop:pt-8",
          {
            "min-w-[285px] tablet:min-w-[332px] laptop:min-w-[283px] desktop:min-w-[342px]":
              isExpanded || isFixedOpen,
          },
        )}
      >
        {children}
      </div>
    );
  },
);

SidebarContentWrapper.displayName = "SidebarContentWrapper";

export default SidebarContentWrapper;
