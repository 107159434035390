"use client";

import { useRef } from "react";
import { twMerge } from "tailwind-merge";

import NavigationDrawerIconButton from "./NavigationDrawerIconButton";
import { useSidebarContext } from "./useSidebar";
import SidebarContentWrapper from "./SidebarContentWrapper";

export default function Sidebar({
  children = undefined,
  className = undefined,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  const { setIsExpanded, isFixedOpen } = useSidebarContext();
  const sidebarRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={twMerge(
        `${isFixedOpen ? "sticky" : "absolute"} flex h-full`,
        className,
      )}
    >
      <div
        className="relative"
        onMouseEnter={e => {
          // prevent expanding when hovering over the icon button
          if (sidebarRef.current === e.target && !isFixedOpen) {
            setIsExpanded?.(true);
          }
        }}
        onMouseLeave={() => setIsExpanded?.(false)}
      >
        <div className="absolute -right-4 top-4">
          <NavigationDrawerIconButton />
        </div>
        <SidebarContentWrapper ref={sidebarRef}>
          {children}
        </SidebarContentWrapper>
      </div>
    </div>
  );
}
