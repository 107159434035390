"use client";

import Logo from "@/app/_components/logo";
import { Header, HeaderTitle } from "@/app/_components/header";
import Link from "next/link";
import LogoutButton from "@/app/(partner)/_components/LogoutButton";
import { PartnerSidebarMobile } from "../partnerSidebar";

interface PartnerNavbarProps {
  partnerName: string;
}

const PartnerNavbar: React.FC<PartnerNavbarProps> = ({ partnerName }) => (
  <Header widthFull sticky>
    <Link href="/">
      <Logo variant="icon" className="h-8 w-8 tablet:hidden" />
      <Logo className="hidden w-32 tablet:block desktop:w-40" />
    </Link>
    <div className="flex grow items-center justify-between gap-2">
      <HeaderTitle className="truncate">{partnerName}</HeaderTitle>
      <LogoutButton type="Button" />
      <div className="flex laptop:hidden">
        <PartnerSidebarMobile />
      </div>
    </div>
  </Header>
);

export default PartnerNavbar;
