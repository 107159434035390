"use client";

import LogoutButton from "@/app/(partner)/_components/LogoutButton";
import SidebarFooterSection from "./SidebarFooterSection";
import NavigationDrawerItem from "./NavigationDrawerItem";
import { useSidebarContext } from "./useSidebar";

export default function SidebarFooter() {
  const { isExpanded, isFixedOpen } = useSidebarContext();

  return (
    <div className="mt-auto">
      <SidebarFooterSection>
        {!isExpanded && !isFixedOpen ? (
          <NavigationDrawerItem as="link" label="" href="#" iconName="legal" />
        ) : (
          <>
            <NavigationDrawerItem
              as="link"
              label="Impressum"
              href="/impressum/"
            />
            <NavigationDrawerItem
              as="link"
              label="Datenschutzerklärung"
              href="/datenschutz/"
            />
            <NavigationDrawerItem as="link" label="AGB" href="/agb" />
          </>
        )}
      </SidebarFooterSection>
      <div className="block laptop:hidden">
        <SidebarFooterSection>
          <LogoutButton type="NavItem" />
        </SidebarFooterSection>
      </div>
    </div>
  );
}
