import Link, { LinkProps } from "next/link";
import { usePathname } from "next/navigation";

import classNames from "classnames";
import { useSidebarContext } from "@/app/(partner)/_components/sidebar/useSidebar";
import Icon, { IconName } from "../../../_components/icon";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

type GeneralProps = {
  label: string;
  iconName?: IconName;
};

export type NavigationDrawerItemProps =
  | ({
      as: "link";
    } & LinkProps &
      GeneralProps)
  | ({
      as: "button";
    } & ButtonProps &
      GeneralProps);

export default function NavigationDrawerItem({
  label,
  iconName,
  as,
  ...props
}: NavigationDrawerItemProps) {
  const { isExpanded, isFixedOpen } = useSidebarContext();

  const pathname = usePathname();
  if (as === "link") {
    const { href } = props as LinkProps;
    const isActive = href === pathname;
    return (
      <Link {...(props as LinkProps)} className="w-full">
        <div
          className={classNames(
            "flex items-start gap-x-3 bg-background p-3 hover:bg-primary-50",
            { "bg-primary-100": isActive },
          )}
        >
          {iconName && <Icon className="h-6 w-6" name={iconName} />}
          {(isExpanded || isFixedOpen) && label}
        </div>
      </Link>
    );
  }
  return (
    <button {...(props as ButtonProps)} className="w-full">
      <div className="flex items-start gap-x-3 bg-background p-3 hover:bg-primary-50">
        {iconName && <Icon className="h-6 w-6" name={iconName} />}
        {(isExpanded || isFixedOpen) && label}
      </div>
    </button>
  );
}
