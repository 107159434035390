"use client";

import { MenuItem, Menu, Section, Header } from "react-aria-components";
import classNames from "classnames";

import { partnerHasHeatpumpOnly } from "@/app/(partner)/_helpers/partner";
import NavigationDrawerItem from "./NavigationDrawerItem";
import { useSidebarContext } from "./useSidebar";
import { type IconName } from "../../../_components/icon";

interface ISection {
  title?: string;
  sectionId: string;
  items: {
    id: string;
    label: string;
    href: string;
    iconName: IconName;
    itemType: "link" | "button";
    pvOnly?: boolean;
  }[];
}

export interface NavigationDrawerListProps {
  menuItems: ISection[];
}

export default function NavigationDrawerList({
  menuItems,
}: NavigationDrawerListProps) {
  const { isExpanded, isFixedOpen, partnerInfo } = useSidebarContext();

  const heatPumpOnly = partnerInfo
    ? partnerHasHeatpumpOnly(partnerInfo)
    : false;

  return (
    <Menu className="bg-background" aria-label="Navigation">
      {menuItems.map(section => (
        <Section key={section.sectionId}>
          {section.title && (
            <Header
              className={classNames(" pb-5 text-xl font-black", {
                "invisible w-0": !(isExpanded || isFixedOpen),
              })}
            >
              {section.title}
            </Header>
          )}
          {section.items.map(item =>
            heatPumpOnly && item.pvOnly ? null : (
              <MenuItem
                key={item.id}
                id={item.id}
                className={classNames({
                  "w-[253px] tablet:w-[292px] laptop:w-[219px] desktop:w-[278px]":
                    isExpanded || isFixedOpen,
                })}
              >
                <NavigationDrawerItem
                  as={item.itemType}
                  href={item.href}
                  label={item.label}
                  iconName={item.iconName}
                />
              </MenuItem>
            ),
          )}
        </Section>
      ))}
    </Menu>
  );
}
