import Icon from "../../../_components/icon";

import { useSidebarContext } from "./useSidebar";

export default function NavigationDrawerIconButton() {
  const { isFixedOpen, setIsFixedOpen } = useSidebarContext();
  return (
    <button
      className="rounded-full bg-background p-1 shadow-sm hover:bg-bluegray-1000 hover:text-white"
      onClick={() => setIsFixedOpen?.(curr => !curr)}
    >
      <Icon
        name={isFixedOpen ? "chevron-left" : "chevron-right"}
        className="h-6 w-6"
      />
    </button>
  );
}
